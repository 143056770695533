<template lang="pug">
    #Demonstracoes
        .container
            ul
                li(v-for="item in items")
                    a(:href="item.file" download target="_blank")
                        .img
                            img(:src="item.image").image
                            .flip
                                p.data {{ item.year }}
</template>
 
<script>
import { props } from '@/mixins/component'

export default {
    name: "demonstracoes",
    props,

    data () {
        return {
            items: this.contents.content.map((item) => {
                return {
                    file: `${process.env.VUE_APP_FILE_API_ROUTE}/files/file/${item.file.id}`,
                    image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`,
                    year: item.year
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped src="./Demonstracoes.styl"></style>
